import useBoundDispatch from 'utils/redux/hooks/use-bound-dispatch';
import * as Actions from 'domains/clubs/actions';
import * as Selectors from 'domains/clubs/selectors';
import useRequestState from 'utils/hooks/use-request-state';
import { useEffect, useMemo } from 'react';
import useSelector from 'utils/redux/hooks/use-selector';
import useSelectorWithProps from 'utils/redux/hooks/use-selector-with-props';
import { useGeoLocation } from 'src/domains/user/hooks';
import { addDistanceToClubs } from 'src/utils/add-distance-to-clubs';
import theme from 'config/theme';

export function useClubs() {
  const doFetchClubs = useBoundDispatch(Actions.fetchClubs);
  const clubs = useSelector(Selectors.getClubs);
  const [isLoading, error, { doSetLoading, doSetError }] = useRequestState();

  useEffect(() => {
    /*
         When clicking on the deal section that goes to the pre-filled checkout, it only has 1 club in the clubs object.
         We repopulate it with all the other clubs when it returns less than 2 clubs, except when it's sportcity premium.
         There it checks if there is at least 1 club, since it only has one club in total so it loops infinitely when checking for less than 2.
       */
    if (
      (Object.keys(clubs).length < 2 && !theme.checkout.onlyShowClub) ||
      (!Object.keys(clubs).length && theme.checkout.onlyShowClub)
    ) {
      doSetLoading(true);
      doFetchClubs()
        .then(() => doSetLoading(false))
        .catch((_error) => doSetError(_error));
    }
  }, [clubs]);

  return [clubs, isLoading, error];
}

export function useClubById(id) {
  const doFetchClub = useBoundDispatch(Actions.fetchClubById);
  const club = useSelectorWithProps(Selectors.getClubById, { id }, [id]);
  const [isLoading, error, { doSetLoading, doSetError }] = useRequestState();

  useEffect(() => {
    if (id && !club) {
      doSetLoading(true);
      doFetchClub(id)
        .then(() => doSetLoading(false))
        .catch((_error) => doSetError(_error));
    }
  }, [club, id]);

  return [club, isLoading, error];
}

export function useAllClubs(enableGeoLocation = true) {
  const [geolocation, , getGeoLocation] = useGeoLocation();

  useEffect(() => {
    if (enableGeoLocation) {
      getGeoLocation();
    }
  }, [enableGeoLocation]);

  const [clubs, isLoading, error] = useClubs();
  const clubsAsArray = useMemo(() => {
    if (!clubs) return [];
    return Object.values(clubs);
  }, [clubs]);

  const allClubs = useMemo(
    () =>
      geolocation
        ? addDistanceToClubs(clubsAsArray, geolocation)
        : clubsAsArray,
    [geolocation, clubsAsArray],
  );

  return [allClubs, isLoading, error];
}
