import classNames from 'classnames'
import styles from './IconButton.module.scss'

export interface IIconButtonProps {
  icon: JSX.Element
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  accessibleLabel?: string
  className?: string
  dataTestId?: string
}

export const IconButton = ({
  icon, onClick, accessibleLabel, className, dataTestId
}: IIconButtonProps) => (
  <button
    className={classNames(
      styles.iconButton,
      className
    )}
    onClick={onClick}
    aria-label={accessibleLabel}
    type="button"
    data-testid={dataTestId || null}
  >
    {icon}
  </button>
)
