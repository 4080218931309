import Head from 'next/head';
import React, { ReactNode } from 'react';
import theme from 'config/theme';
import { isProduction } from '@features/shared/utils/is-production';

interface IMetaHeadProps {
  canonical?: string;
  description?: string;
  title?: string;
  language?: string;
  noIndex?: boolean;
  children?: ReactNode;
}

export const MetaHead = ({
  canonical,
  description,
  title,
  language,
  noIndex,
  children,
}: IMetaHeadProps) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta httpEquiv="language" content={language} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    {(theme.seo.blockSearchEngines || noIndex || !isProduction()) && (
      <meta name="robots" content="noindex, nofollow" />
    )}
    {theme.seo.googleSiteVerification && (
      <meta
        name="google-site-verification"
        content={`${theme.seo.googleSiteVerification}`}
      />
    )}
    {theme.facebookDomainVerification && (
      <meta
        name="facebook-domain-verification"
        content={theme.facebookDomainVerification}
      />
    )}
    {canonical && <link rel="canonical" href={canonical} />}
    {children}
  </Head>
);
