import useBoundDispatch from 'utils/redux/hooks/use-bound-dispatch';
import * as Actions from 'domains/user/actions';
import * as Selectors from 'domains/user/selectors';
import useRequestState from 'utils/hooks/use-request-state';
import { useState } from 'react';
import { pick } from 'ramda';
import useSelector from 'utils/redux/hooks/use-selector';

export function useGeoLocation() {
  const doSetGeoLocation = useBoundDispatch(Actions.setGeoLocation);
  const geoLocation = useSelector(Selectors.getGeolocation);
  const [hasPermission, setPermission] = useState('');
  const [isLoading, error, { doSetLoading, doSetError }] = useRequestState();
  const [mapControlsCallback, setMapControlsCallback] = useState();

  const onSuccessGeoLocation = (position) => {
    setPermission('granted');
    doSetGeoLocation(pick(['latitude', 'longitude'], position.coords))
      .then(() => doSetLoading(false))
      .catch((_error) => doSetError(_error));
    mapControlsCallback &&
      typeof mapControlsCallback.fn === 'function' &&
      position &&
      mapControlsCallback.fn({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
  };

  const onErrorGeoLocation = (error) => {
    doSetError(error);
    setPermission('blocked');
    doSetLoading(false);
  };

  const getGeoLocation = () => {
    doSetLoading(true);
    navigator.geolocation.getCurrentPosition(
      onSuccessGeoLocation,
      onErrorGeoLocation,
      { timeout: 5000 },
    );
  };

  return [
    geoLocation || null,
    isLoading,
    getGeoLocation,
    hasPermission,
    error,
    setMapControlsCallback,
    mapControlsCallback,
  ];
}
