import Icons from 'features/shared/components/icons';
import { Image as IImage } from '@features/pg-funnel/services/contentful/types';
import { Image } from '@features/shared/components/image';
import classNames from 'classnames';
import { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import { useIntl } from 'src/domains/i18n';
import { Body, H3 } from 'src/features/shared/components/typography';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { IUseSheetReturn } from '../../hooks/use-sheet';
import { IconButton } from '../icon-button';
import { Overlay } from '../overlay';
import styles from './index.module.scss';

export interface ISheetProps {
  children: ReactNode;
  sheet: IUseSheetReturn;
  position?: 'middle' | 'right' | 'fullscreen';
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  headerImage?: IImage;
  overlayVariant?: 'opaque' | 'full' | 'none';
  title?: string;
  subtitle?: string;
}

export const Sheet = ({
  children,
  sheet,
  position = 'middle',
  onCloseClick, // FIX being triggered on render of component if isOpening and isOpen is false
  headerImage,
  overlayVariant,
  title,
  subtitle,
}: ISheetProps) => {
  const { formatMessage } = useIntl();
  const { isClosing, close, isOpen, isOpening } = sheet;
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const getAnimation = () => {
    if (position === 'right') {
      return isMobile ? 'slideBottom' : 'slideRight';
    }

    if (position === 'middle') {
      return isMobile ? 'slideBottom' : 'fadeIn';
    }

    return 'fadeIn';
  };

  const handleCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    onCloseClick?.(e);
    close();
  };

  return isOpen || isOpening || isClosing ? (
    <div
      className={classNames(
        styles.container,
        isOpen && styles.isOpen,
        isClosing && styles.closing,
        isOpening && styles.opening,
        styles[position === 'right' && isMobile ? 'middle' : position],
        styles[getAnimation()],
      )}
    >
      {overlayVariant !== 'none' && (
        <Overlay
          accessibleLabel={formatMessage('club-detail.gallery.close')}
          variant={overlayVariant}
          sheet={sheet}
          onClick={onCloseClick}
        />
      )}
      <div
        className={classNames(
          styles.detailsContainer,
          isClosing && styles.closing,
          isOpening && styles.opening,
          isOpen && styles.isOpen,
          position === 'right' && isMobile
            ? [styles.middle, styles.noPadding]
            : styles[position],
          styles[getAnimation()],
        )}
        role="dialog"
      >
        {title && <H3 className={styles.title}>{title}</H3>}
        {subtitle && <Body className={styles.subtitle}>{subtitle}</Body>}
        <div className={styles.iconContainer}>
          <IconButton
            icon={<Icons.CloseIcon />}
            onClick={handleCloseClick}
            accessibleLabel={formatMessage('club-detail.gallery.close')}
            dataTestId="sheet-close-button"
          />
        </div>
        <div
          className={classNames(styles.childWrapper, {
            [styles.noPadding]: position === 'right' && !isMobile,
          })}
        >
          {headerImage ? (
            <div className={styles.imageContainer}>
              <Image className={styles.headerImage} image={headerImage} />
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};
